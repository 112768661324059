import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Typography } from '@mui/material'
import coming_soon from '../../undraw/coming_soon.svg'
import './dehumidifier.css'

function Dehumidifier() {
  return (
    <div>
        <Navbar />
        <Typography className='coming-soon-title' variant='h2' textAlign='center' mt={13} mb={3}>Coming soon!</Typography>
        <img className='coming-soon-image' width='600px' style={{display: 'block', margin: '0 auto'}} src={coming_soon} />
        <Footer />
    </div>
  )
}

export default Dehumidifier