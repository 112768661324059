const floricultureData = [
    {
        location: 'Indo Dutch Horticulture, Bhimtal',
        rooms: '7 Rooms (+2°C, -6°C)',
        room: false,
        room1: false,
        room2: false
    },
    {
        location: 'Kashipur Agro, Kashipur',
        room1: 'Pre-Cooler (+4°C, 98% RH )',
        room2: 'Storage (+4°C, 98% RH )',
        rooms: false,
        room: false
    },
    {
        location: 'Vijay Floriculture, Kashipur',
        room1: 'High RH (+4°C, 98% RH )',
        room2: '-6°C',
        rooms: false,
        room: false
    },
    {
        location: 'Reethi Beach Resort,  Maldives',
        room1: 'For Fish (-26°C)',
        room2: 'For F & V (+2°C)',
        rooms: false,
        room: false
    },
    {
        location: 'Season Farms (Peas), Sonipat',
        room1: 'Blast Cooling for  Peas (-30°C)',
        room2: 'Storage (-18°C)',
        rooms: false,
        room: false
    },
    {
        location: 'Akshardham, Delhi (Fruits &amp; Vegetables - 6 Cold Rooms)',
        room1: false,
        room2: false,
        rooms: false,
        room: '+2°C'
    },
    {
        location: 'Country-Inn, Bhiwadi (4 Cold Rooms)',
        room1: false,
        room2: false,
        rooms: false,
        room: '-20°C'
    },
    {
        location: 'SI Bang Bakery, Gurgaon',
        room1: false,
        room2: false,
        rooms: false,
        room: '-20°C'
    },
    {
        location: 'Khera Trading, Panipat (Frozen Foods)',
        room1: false,
        room2: false,
        rooms: false,
        room: '-20°C'
    },
    {
        location: 'Landmark Dairy Products, Sohna',
        room1: false,
        room2: false,
        rooms: false,
        room: '+2°C'
    },
]

export default floricultureData;