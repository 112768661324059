// NOTE: I'm using the same CSS as mushroomfarms, since layout is completely similar

import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Typography, Grid, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './mushroomfarms.css'

function Slaughterhouse() {
  const navigate = useNavigate()

  const mushroom_sites = [
    {
        location: 'Nepal',
        production: '20 tons / day Blast Refrigeration Capacity',
        imagePath: '/machines-images/Slaughterhouse/nepal.jpg'
    },
  ]

  return (
    <div>
        <Navbar />
        <div className='mushroom-product-content'>
          <Typography className='mushroom-product-title' textAlign='center' fontWeight='bold' variant='h2' color='#36454F' mb={2}>Refrigeration for Chicken Slaughterhouse</Typography>
          <Grid container justifyContent='center' className='mushroom-cards-container'>
          {mushroom_sites.map((site, index) => {
            return (
                <Grid md={6} item className='mushroom-card'>
                    <div>
                      <Typography className='mushroom-card-title' textAlign='center' fontWeight='bold' variant='h4' mb={2}>{site.location}</Typography>

                      <img src={process.env.PUBLIC_URL + site.imagePath} alt='mushroom farming' className='mushroom-card-image' />

                      <Typography textAlign='center' mb={2} variant='h5' color='#BEBEBE' className='mushroom-card-caption'>{site.production}</Typography>

                    </div>
                    <Button onClick={() => navigate('/product/slaughterhouse/nepal')} variant='contained' className='mushroom-card-button'>View More</Button>
              </Grid>
            )
          })}
          </Grid>
        </div>
        <Footer />
    </div>
  )
}

export default Slaughterhouse