// TODO: Just use a pdf instead of images. This is a bad approach and non scalable.

import React from 'react'
import Navbar from '../../../components/Navbar'
import Footer from '../../../components/Footer'
import './mushroom.css'

function Murthal() {
    const images = ['Slide001', 'Slide002', 'Slide003', 'Slide004', 'Slide005', 'Slide006', 'Slide007', 'Slide008', 'Slide009', 'Slide010', 'Slide011', 'Slide012']
    return (
        <div>
            <Navbar />
            <div className='product-content'>
                {images.map((image, index) => (<img className='slideImg2' key={index} src={process.env.PUBLIC_URL + '/machines-images/Mushroom Farms/Murthal/' + image + '.jpeg'} />))}
            </div>
            <Footer />
        </div>
    )
}

export default Murthal