import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Typography } from '@mui/material'
import co2tankData from './product-data/co2tankData'

import './co2tank.css'

function Co2tankstorage() {

  return (
    <div>
        <Navbar />
        <div className='product-content'>
          <div className='product-title-and-description'>
            <Typography className='co2-product-title' fontWeight='bold' variant='h2' color='#36454F' mb={1}>CO₂ / N₂O Tank Storage</Typography>
          </div>

          {co2tankData.map((co2tank, index) => {
            return (
              <div key={index} className='co2tank-wrapper'>
                <Typography variant='h4' className='co2tank-title' fontWeight='bold' color='#86868b'>{co2tank.name}</Typography>
                <div className='co2tank-container'>
                  <img className='co2tank-image' src={process.env.PUBLIC_URL + co2tank.imagePath} />
                  <div className='co2tank-content'>
                    <div className='co2tank-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Location: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{co2tank.location}</Typography>
                    </div>
                    <div className='co2tank-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Tank capacity: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{co2tank.tankCapacity}</Typography>
                    </div>
                    <div className='co2tank-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Refrigerant: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{co2tank.refrigerant}</Typography>
                    </div>
                    {co2tank.water_cooled_condenser && <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>Water-Cooled condenser</Typography>}
                    <div className='co2tank-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Heat Exchanger: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{co2tank.heatExchanger}</Typography>
                    </div>
                    {co2tank.remote && <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>Control using smartphone or PC</Typography>}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <Footer />
    </div>
  )
}

export default Co2tankstorage