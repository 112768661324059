const co2tankData = [
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Bahrain, Middle East",
        tankCapacity: "250 m³ X 4",
        refrigerant: "R404a",
        refUnit: "Water-Cooled X 4",
        heatExchanger: "Internal",
        imagePath: "/machines-images/CO2 Tank/beh.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Abu Dhabi, UAE",
        tankCapacity: "70 m³ X 3",
        refrigerant: "R404a",
        refUnit: "Water Cooled",
        heatExchanger: "Internal",
        imagePath: "/machines-images/CO2 Tank/Abu_70klx3.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Coca Cola, Amethi, Uttar Pradesh, India",
        tankCapacity: "100 m³",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "Internal",
        imagePath: "/machines-images/CO2 Tank/coca_cola_amethi.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Cote d’Ivoire, West Africa",
        tankCapacity: "30 m³",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "External",
        imagePath: "/machines-images/CO2 Tank/Algeria_CO2_Storage_Tank_Unit.jpeg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Lubumbashi, Congo, Africa",
        tankCapacity: "20 m³ X 2",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "External",
        imagePath: "/machines-images/CO2 Tank/Lubumbashi_Congo.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Cote d’Ivoire, West Africa",
        tankCapacity: "30 m³",
        refrigerant: "R404a",
        refUnit: "Air Cooled",
        heatExchanger: "Internal",
        vaporizer: "Forced Air",
        capacity: "500 kg/hr",
        imagePath: "/machines-images/CO2 Tank/storage_africa1.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Sri Lanka, Asia",
        tankCapacity: "30 m³",
        refrigerant: "R404a",
        refUnit: "Air Cooled",
        heatExchanger: "Internal",
        imagePath: "/machines-images/CO2 Tank/srilanka.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Uttar Pradesh, India",
        tankCapacity: "30 m³",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "Internal",
        imagePath: "/machines-images/CO2 Tank/co2ref2.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Andhra Pradesh, India",
        tankCapacity: "30 m³",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "Internal",
        imagePath: "/machines-images/CO2 Tank/co2ref7.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Punjab, India",
        tankCapacity: "30 m³",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "External",
        imagePath: "/machines-images/CO2 Tank/co2ref4.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Maharashtra, India",
        tankCapacity: "20 m³ X 3",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "External",
        imagePath: "/machines-images/CO2 Tank/co2ref6.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Madhya Pradesh, India",
        tankCapacity: "30 m³",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "External",
        imagePath: "/machines-images/CO2 Tank/gwalior.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Uttar Pradesh, India",
        tankCapacity: "30 m³",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "External",
        imagePath: "/machines-images/CO2 Tank/co2ref.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Punjab, India",
        tankCapacity: "30 m³",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "External",
        imagePath: "/machines-images/CO2 Tank/jandh.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Haryana, India",
        tankCapacity: "30 m³",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "External",
        imagePath: "/machines-images/CO2 Tank/haryana.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Myanmar, Asia",
        tankCapacity: "20 m³",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "Internal",
        imagePath: "/machines-images/CO2 Tank/myanmar_co2_storage.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Tanzania, Africa",
        tankCapacity: "20 m³",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "External",
        imagePath: "/machines-images/CO2 Tank/Tanzania_Africa.jpg"
    },
    {
        name: "Refrigeration unit for CO₂ Liquefaction: Tank Storage",
        location: "Haryana, India",
        tankCapacity: "10 m³",
        refrigerant: "R404a",
        refUnit: "Air cooled",
        heatExchanger: "Internal",
        imagePath: "/machines-images/CO2 Tank/co2ref1.jpg"
    },
]

export default co2tankData