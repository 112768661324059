const iceMachineData = [
    {
      name: 'Tube-Ice Machine 10 TPD',
      location: 'Bhiwadi, Rajasthan',
      capacity: '10 Tons Per Day x 2 Machines',
      compressor: 'Screw',
      refrigerant: 'R404a',
      water_cooled_condenser: true,
      controller: 'PLC with Touch Screen',
      remote: true,
      imagePath1: '/machines-images/Tube Ice Machine/sare_1.jpg',
      imagePath2: '/machines-images/Tube Ice Machine/sare_2.jpg'
    },
    {
      name: 'Tube-Ice Machine 10 TPD',
      location: 'Tapukara, Rajasthan',
      capacity: '10 Tons Per Day',
      compressor: 'Screw',
      refrigerant: 'R404a',
      water_cooled_condenser: true,
      controller: 'PLC with Touch Screen',
      remote: true,
      imagePath1: '/machines-images/Tube Ice Machine/tapu_1.jpg',
      imagePath2: '/machines-images/Tube Ice Machine/tapu_2.jpg'
    },
    {
      name: 'Tube-Ice Machine 10 TPD',
      location: 'Mandi Gobindgarh, Punjab',
      capacity: '10 Tons Per Day',
      compressor: 'Screw',
      refrigerant: 'R404a',
      water_cooled_condenser: true,
      controller: 'PLC with Touch Screen',
      remote: true,
      imagePath1: '/machines-images/Tube Ice Machine/punjab_1.jpg',
      imagePath2: '/machines-images/Tube Ice Machine/punjab_2.jpg'
    },
    {
      name: 'Tube-Ice Machine 5 TPD',
      location: 'Mandi Gobindgarh, Punjab',
      capacity: '5 Tons Per Day',
      compressor: 'Recip.',
      refrigerant: 'R404a',
      water_cooled_condenser: true,
      controller: 'PLC with Touch Screen',
      remote: true,
      imagePath1: '/machines-images/Tube Ice Machine/cbpl_51.jpg',
      imagePath2: '/machines-images/Tube Ice Machine/cbpl_52.jpg'
    },
    {
      name: 'Tube-Ice Machine 5 TPD',
      location: 'Sonipat, Uttar Pradesh',
      capacity: '5 Tons Per Day',
      compressor: 'Recip.',
      refrigerant: 'R404a',
      water_cooled_condenser: true,
      controller: 'PLC with Touch Screen',
      remote: true,
      imagePath1: '/machines-images/Tube Ice Machine/soni_1.jpg',
      imagePath2: '/machines-images/Tube Ice Machine/soni_2.jpeg'
    },
    {
      name: 'Tube-Ice Machine 5 TPD',
      location: 'Greater Noida, Uttar Pradesh',
      capacity: '5 Tons Per Day',
      compressor: 'Recip.',
      refrigerant: 'R404a',
      water_cooled_condenser: true,
      controller: 'PLC with Touch Screen',
      remote: true,
      imagePath1: '/machines-images/Tube Ice Machine/suraj_51.jpg',
      imagePath2: '/machines-images/Tube Ice Machine/suraj_52.jpg'
    },
]

export default iceMachineData