import React from 'react'
import {useParams} from 'react-router-dom'
import Coldstore from './products/Coldstore'
import Heatpump from './products/Heatpump'
import Dehumidifier from './products/Dehumidifier'
import Co2liquefaction from './products/Co2liquefaction'
import Co2tankstorage from './products/Co2tankstorage'
import Icemachine from './products/Icemachine'
import Processchiller from './products/Processchiller'
import Wasteheatrecovery from './products/Wasteheatrecovery'
import Mushroomfarms from './products/Mushroomfarms'
import Slaughterhouse from './products/Slaughterhouse'

function Product() {
    const mapping = {
        coldstores: <Coldstore />,
        heatpumps: <Heatpump />,
        dehumidifiers: <Dehumidifier />,
        co2liquefaction: <Co2liquefaction />,
        co2tankstorage: <Co2tankstorage />,
        icemachines: <Icemachine />,
        processchillers: <Processchiller />,
        wasteheatrecovery: <Wasteheatrecovery />,
        mushroomfarms: <Mushroomfarms />,
        slaughterhouse: <Slaughterhouse />
    }
    const {productId} = useParams()
    return mapping[`${productId}`]
}

export default Product