import React from 'react'
import Navbar from '../../../components/Navbar'
import Footer from '../../../components/Footer'
import a1 from '../../../machines-images/Waste Heat Recovery/a1.jpg'
import a2 from '../../../machines-images/Waste Heat Recovery/a2.jpg'
import a3 from '../../../machines-images/Waste Heat Recovery/a3.jpg'
import a4 from '../../../machines-images/Waste Heat Recovery/a4.jpg'
import a5 from '../../../machines-images/Waste Heat Recovery/a5.jpg'
import a6 from '../../../machines-images/Waste Heat Recovery/a6.jpg'
import a7 from '../../../machines-images/Waste Heat Recovery/a7.jpg'
import a8 from '../../../machines-images/Waste Heat Recovery/a8.jpg'
import a9 from '../../../machines-images/Waste Heat Recovery/a9.jpg'
import a10 from '../../../machines-images/Waste Heat Recovery/a10.jpg'
import './whr.css'

function Whr_reethi() {
    const images = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10]
    return (
        <div>
            <Navbar />
            <div className='product-content'>
                {images.map((image, index) => (<img key={index} className='whr-slides' src={image} />))}
            </div>
            <Footer />
        </div>
    )
}

export default Whr_reethi