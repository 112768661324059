import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Typography } from '@mui/material'
import co2liquefactionData from './product-data/co2liquefactionData'

import './co2liquefaction.css'

function Co2liquefaction() {

  co2liquefactionData.sort((a, b) => {
      return b.capacityTPD - a.capacityTPD;
  });

  return (
    <div>
        <Navbar />
        <div className='product-content'>
          <div className='product-title-and-description'>
            <Typography className='co2-product-title' fontWeight='bold' variant='h2' color='#36454F' mb={1}>CO₂ / N₂O: Liquefaction</Typography>
          </div>

          {co2liquefactionData.map((co2gen, index) => {
            return (
              <div key={index} className='co2gen-wrapper'>
                <Typography variant='h4' className='co2gen-title' fontWeight='bold' color='#86868b'>{co2gen.name}</Typography>
                <div className='co2gen-container'>
                <img className='co2gen-image' src={process.env.PUBLIC_URL + co2gen.imagePath} alt={co2gen.name} />
                  <div className='co2gen-content'>
                    <div className='co2gen-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Location: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{co2gen.location}</Typography>
                    </div>
                    <div className='co2gen-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Capacity: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{co2gen.capacityTPD} TPD</Typography>
                    </div>
                    <div className='co2gen-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Compressor: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{co2gen.compressor}</Typography>
                    </div>
                    <div className='co2gen-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Refrigerant: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{co2gen.refrigerant}</Typography>
                    </div>
                    {co2gen.water_cooled_condenser && <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>Water-Cooled condenser</Typography>}
                    <div className='co2gen-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Controller: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{co2gen.controller}</Typography>
                    </div>
                    {co2gen.under_production && <Typography variant='h5' mb={2} color='#36454F' fontWeight={'bold'} className='machine-text'>Unit under production/installation</Typography>}
                    {co2gen.remote && <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>Control using smartphone or PC</Typography>}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <Footer />
    </div>
  )
}

export default Co2liquefaction