import React from 'react'
import {useNavigate} from 'react-router-dom'
import Typography from '@mui/material/Typography';
import { Typewriter } from 'react-simple-typewriter'
import './navbar.css'

function Navbar() {
  const navigate = useNavigate()
  const [productHovered, setProductHovered] = React.useState(false);
  const [hamburgerClicked, setHamburgerClicked] = React.useState(false);
  const products = ["CO₂ and N₂O Liquefaction", "CO₂ and N₂O Tank Storage", "Heat Pumps", "Mushroom Farms Climate Control", "Refrigeration for Slaughterhouse", "Ice Machines", "Process Chillers", "Refrigeration for Potato Storage", "Dehumidifiers", "Waste Heat Recovery"]
  const productRedirects = ["co2liquefaction", "co2tankstorage", "heatpumps", "mushroomfarms", "slaughterhouse", "icemachines", "processchillers", "coldstores", "dehumidifiers", "wasteheatrecovery"]
  return (
    <>
    <div className={`navbar-container ${productHovered ? 'hovered' : ''}`} onMouseLeave={() => setProductHovered(false)}>
      <div className="navbar">
        <Typography mt={1} variant='h4' className='title' onClick={() => navigate('/')}>
          <Typewriter
            words={['celsius.in']}
            loop={1}
          />
        </Typography>
        <div className="navbar-list">
          <ul className={`list ${hamburgerClicked ? 'active' : ''}`}>
            <i className={`fas fa-times fa-2x ${hamburgerClicked ? '' : 'mobile-list-open'}`} onClick={() => setHamburgerClicked(false)}></i>
            <div className="products" onMouseEnter={() => setProductHovered(true)}>
              <li><a className={`product-text ${productHovered ? 'hovered': ''}`}>Products</a></li>
              <i className={`fa-solid fa-caret-down ${productHovered ? 'hovered': ''}`}></i>
              <i className={`fa-solid fa-caret-up ${productHovered ? 'hovered': ''}`}></i>
            </div>
            {products.map((product, index) => (
              <li key={index} onClick={() => navigate(`/product/${productRedirects[index]}`)} className="mobile-products"><a>{product}</a></li>
            ))}
            <li className="about" onClick={() => navigate('/about')}><a>About</a></li>
            <li onClick={() => navigate('/contactus')}><a>Contact Us</a></li>
          </ul>
        </div>
        <div className="mobile">
          <i className={`fas fa-bars fa-2x ${hamburgerClicked ? 'mobile-list-open' : ''}`} onClick={() => setHamburgerClicked(true)}></i>
        </div>
      </div>
      <div className={`product-list ${productHovered ? 'hovered' : ''}`}>
          {products.map((product, index) => (
              <a key={index} onClick={() => navigate(`/product/${productRedirects[index]}`)} style={{fontSize: '16px', fontWeight: 500, cursor: 'pointer'}} className='meh'>{product}</a>
          ))}
      </div>
    </div>
    <div className={`blur ${hamburgerClicked ? 'active' : ''} ${productHovered ? 'active' : ''}`}></div>
    </>
  )
}

export default Navbar