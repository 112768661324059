import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Typography } from '@mui/material'
import './about.css'
import company from '../undraw/company.svg'
import products from '../undraw/products.svg'
import nature from '../undraw/nature.svg'
import factory from '../undraw/factory.svg'
import world_locations from '../undraw/world_locations.svg'

function About() {
  return (
    <div>
        <Navbar />
        <div className='about-content'>
          <Typography className='about-title' fontWeight='bold' variant='h2' color='#36454F' mb={2} ml={4}>About Us</Typography>
          <div className='about-part-wrapper'>
            <div className='about-part'>
              <div className='about-text-container'>
                <Typography className='about-text'>Celsius is in the field of Industrial Refrigeration making customized Refrigeration Units to control Temperature, Humidity and CO2 for Cold Stores and Industrial Applications.</Typography>
              </div>
              <img src={company} className='about-image' />
            </div>
            <div class="custom-shape-divider-bottom-1689350389 lightgray">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                </svg>
            </div>
          </div>


            <div className='about-part-wrapper gray'>
              <Typography className='about-subtitle' fontWeight='bold' variant='h3' color='#36454F' mt={3} ml={4}>Our products</Typography>
              <div className='about-part'>
                <img src={products} className='about-image' />
                <div className='about-text-container'>
                  <ul style={{display: 'flex', flexDirection: 'column', listStyleType: 'circle'}}>
                    <li><Typography className='about-text'>Refrigeration Units for Gas Liquefaction: CO₂ , N₂O , CO₂ separation from Biogas</Typography></li>
                    <li><Typography className='about-text'>Refrigeration Units for Cold Stores, Blast Freezers, Blast Chillers: for Fruits & Vegetables, Meat & Poultry, etc</Typography></li>
                    <li><Typography className='about-text'>Refrigeration Units for Mushroom Farms: Growing Rooms & Tunnels</Typography></li>
                    <li><Typography className='about-text'>Ice Machines: Tube Ice (from 5 TPD onwards), Flake Ice (from 1 TPD onwards)</Typography></li>
                    <li><Typography className='about-text'>Process Chillers: for Exo-Gas, Milk, Water, Glycol</Typography></li>
                    <li><Typography className='about-text'>Heat Pumps for Heating water in Swimming Pools and for Bathrooms in Hostels & Hotels</Typography></li>
                  </ul>
                </div>
              </div>
              <div class="custom-shape-divider-bottom-1689350389 white">
                  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                      <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                  </svg>
              </div>
            </div>

            <div className='about-part-wrapper'>
              <Typography className='about-subtitle' fontWeight='bold' variant='h3' color='#36454F' mt={3} ml={4}>Environment Friendly</Typography>
              <div className='about-part'>
                <div className='about-text-container'>
                  <Typography className='about-text'>We use Eco-friendly Refrigerants (R410a etc) or Natural Refrigerants (Ammonia) in our Refrigeration Systems using Scroll, Recip, Screw Compressors.</Typography>
                </div>
                <img src={nature} className='about-image' />
              </div>
              <div class="custom-shape-divider-bottom-1689350389 lightgray">
                  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                      <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                  </svg>
              </div>
            </div>

            <div className='about-part-wrapper gray'>
              <div className='about-part'>
                <img src={factory} className='about-image' />
                <div className='about-text-container'>
                  <Typography className='about-text'>We have a 30,000 ft² Factory at RIICO Industrial Area, EPIP, Neemrana, Rajasthan with inhouse Facilities for making Refrigeration Units: Mechanical, Electrical and Electronics.</Typography>
                </div>
              </div>
              <div class="custom-shape-divider-bottom-1689350389 white">
                  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                      <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                  </svg>
              </div>
            </div>

            <div className='about-part-wrapper'>
              <Typography className='about-subtitle' fontWeight='bold' variant='h3' color='#36454F' mt={3} ml={4}>Multinational Coverage</Typography>
              <div className='about-part'>
                <div className='about-text-container'>
                  <Typography className='about-text'>Our Installations are Pan India and in several countries.</Typography>
                </div>
                <img src={world_locations} className='about-image' />
              </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default About