const processChillerData = [
    {
        name: "Air Cooled Scroll Chiller (R410a)",
        cooling_capacity: "80 HP",
        installation: "Huamantla, Mexico",
        application: "Hydrocooler for Carrot Cooling",
        compressor: "Twin Scroll (Tandem design)",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/Humantla_Mexico_Chiller.jpg"
    },
    {
        name: "Air Cooled Scroll Chiller (R410a)",
        cooling_capacity: "2 units of 80 HP",
        installation: "Mexico",
        application: "Hydrocooler for Carrot Cooling",
        compressor: "Twin Scroll (Tandem design)",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/mexico.jpg"
    },
    {
        name: "Air Cooled Scroll Chiller (R410a)",
        cooling_capacity: "120 HP",
        installation: "Puebla, Mexico",
        application: "Hydrocooler for Carrot Cooling",
        compressor: "Three Scroll (Tandem design)",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/Puebla_Mexico_Chiller.jpeg"
    },
    {
        name: "Air Cooled Scroll Chiller (R410a)",
        cooling_capacity: "96 HP",
        installation: "Australia",
        application: "Hydrocooler for Carrot Cooling",
        compressor: "Triple Scroll (Tandem design)",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/Chilleraust_bulg.jpg"
    },
    {
        name: "Air Cooled Scroll Chiller (R410a)",
        cooling_capacity: "96 HP",
        installation: "Sofia, Bulgaria",
        application: "Hydrocooler for Carrot Cooling",
        compressor: "Triple Scroll (Tandem design)",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/Bulgaria_Chiller.jpg"
    },
    {
        name: "Air Cooled Scroll Chiller (R410a)",
        cooling_capacity: "80 HP",
        installation: "Egypt, Africa",
        application: "Hydrocooler for Carrot Cooling",
        compressor: "",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/Egypt.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller (R410a)",
        cooling_capacity: "120 HP",
        installation: "Africa",
        application: "RO Water Cooling",
        compressor: "Triple Scroll (Tandem design)",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/hp120_ROwater.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller (R410a)",
        cooling_capacity: "2 units of 96 HP",
        installation: "Himmatnagar, Gujarat",
        application: "Potato Cold Store",
        compressor: "Triple Scroll (Tandem design)",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/ashtha.jpg"
    },
    {
        name: "Air Cooled Scroll Chiller (R404a)",
        cooling_capacity: "2 units of 45 HP",
        installation: "Bhiwadi, Rajasthan",
        application: "Airconditioning",
        compressor: "Triple Scroll (Tandem design)",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/bhiwadi.jpg"
    },
    {
        name: "WATER COOLED SCROLL CHILLER",
        cooling_capacity: "2 units of 10 HP",
        installation: "Chhattisgarh, India",
        application: "EXO gas chiller",
        compressor: "Scroll",
        plc: "Digital Controller",
        imagePath: "/machines-images/Process Chiller/Chhattisgarh_8.5x2.jpg"
    },
    {
        name: "Air Cooled Scroll Chiller (R410a)",
        cooling_capacity: "32 HP",
        installation: "Ambala, Haryana",
        application: "Airconditioning",
        compressor: "",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/allroundambala.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller",
        cooling_capacity: "2 units of 15 HP",
        installation: "Jhajjar, Haryana",
        application: "Banana Ripening",
        compressor: "",
        plc: "",
        imagePath: "/machines-images/Process Chiller/wkjhajjar.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller",
        cooling_capacity: "2 units of 15 HP",
        installation: "Roorkee, Uttarakhand",
        application: "Banana Ripening",
        compressor: "",
        plc: "",
        imagePath: "/machines-images/Process Chiller/roorkee.jpg"
    },
    {
        name: "Air Cooled Scroll Chiller (R404a)",
        cooling_capacity: "2 units of 15 HP",
        installation: "Srinagar, J&K",
        application: "Banana Ripening",
        compressor: "",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/hnagri.jpg"
    },
    {
        name: "Air Cooled Scroll Chiller",
        cooling_capacity: "2 units of 15 HP",
        installation: "Srinagar, J&K",
        application: "Banana Ripening",
        compressor: "",
        plc: "",
        imagePath: "/machines-images/Process Chiller/shrinagar.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller",
        cooling_capacity: "2 units of 10HP with 1 Tank",
        installation: "Jaipur (Rajasthan), Greater Noida (UP), Hoshiarpur (Punjab),  Barhi (Haryana)",
        application: "Bread Manufacturing",
        compressor: "",
        plc: "",
        imagePath: "/machines-images/Process Chiller/harvestgold.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller",
        cooling_capacity: "20 HP",
        installation: "Roorkee (Uttarakhand), Sonipat (Haryana)",
        application: "Bread Manufacturing",
        compressor: "Twin Scroll (Tandem design)",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/shivasheesh.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller",
        cooling_capacity: "6 HP",
        installation: "Sonipat (Haryana)",
        application: "Bread Manufacturing",
        compressor: "",
        plc: "",
        imagePath: "/machines-images/Process Chiller/aditiyabirla.jpg"
    },
    {
        name: "Air Cooled Scroll Chiller",
        cooling_capacity: "6 HP",
        installation: "Delhi, Faridabad (Haryana), Mumbai, Greater Noida (UP)",
        application: "Cooling of Laser Die Cutting Machine",
        compressor: "",
        plc: "",
        imagePath: "/machines-images/Process Chiller/ashokpuncharts.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller",
        cooling_capacity: "2 units of 15 HP",
        installation: "Noida (UP)",
        application: "ULT Condenser Cooling",
        compressor: "",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/barco.jpg"
    },
    {
        name: "Air Cooled Scroll Chiller",
        cooling_capacity: "10 HP",
        installation: "Delhi",
        application: "Spa Therapy",
        compressor: "",
        plc: "",
        imagePath: "/machines-images/Process Chiller/chillerswamip.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller",
        cooling_capacity: "2 units of 10 HP",
        installation: "Delhi NCR",
        application: "Exo Gas Cooling",
        compressor: "",
        plc: "",
        imagePath: "/machines-images/Process Chiller/Alliedgood.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller",
        cooling_capacity: "80 HP",
        installation: "Nepal",
        application: "Instant Milk Chiller (5000 LPH)",
        compressor: "Twin Scroll (Tandem design)",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/GSD485.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller",
        cooling_capacity: "2 x 10 HP",
        installation: "Nepal",
        application: "Instant Milk Chiller (2x700 LPH)",
        compressor: "",
        plc: "",
        imagePath: "/machines-images/Process Chiller/InstantMilkChiller.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller",
        cooling_capacity: "14 HP",
        installation: "Nepal",
        application: "Instant Milk Chiller (1000 LPH)",
        compressor: "Twin Scroll (Tandem design)",
        plc: "PLC Controlled, Electronic Expansion Valve",
        imagePath: "/machines-images/Process Chiller/milkchiller14hp.jpg"
    },
    {
        name: "Water Cooled Scroll Chiller",
        cooling_capacity: "12.5 + 12.5 tons",
        installation: "Tamil Nadu, India",
        application: "EXO Gas Chiller",
        compressor: "Scroll",
        plc: "Digital Controller",
        imagePath: "/machines-images/Process Chiller/tamil_nadu_exogas_chiller.jpg"
    }
]

export default processChillerData