import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import About from './pages/About'
import Contactus from './pages/Contactus';
import Product from './pages/Product';
import HeatpumpImages from './pages/products/HeatpumpImages';
import Whr_wynberg from './pages/products/waste_heat_recovery/Whr_Wynberg';
import Whr_reethi from './pages/products/waste_heat_recovery/Whr_reethi';
import Mushroom from './pages/products/coldstores/Mushroom';
import Potato from './pages/products/coldstores/Potato';
import Floriculture from './pages/products/coldstores/Floriculture';
import Samalkha from './pages/products/mushroom_farms/Samalkha';
import Murthal from './pages/products/mushroom_farms/Murthal';
import Garhmukhteshwar from './pages/products/mushroom_farms/Garhmukhteshwar';
import Nepal from './pages/products/slaughterhouse/Nepal.jsx';

import {
	createBrowserRouter,
	RouterProvider,
	Navigate
}  from "react-router-dom"

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />
	}, 
	{
		path: "/about",
		element: <About />
	},
	{
		path: "/contactus",
		element: <Contactus />
	},
	{
		path: "/product/:productId",
		element: <Product />
	},
	{
		path: "/product/heatpump/imagegallery",
		element: <HeatpumpImages />
	},
	{
		path: "product/wasteheatrecovery/wynberg",
		element: <Whr_wynberg />
	},
	{
		path: "product/wasteheatrecovery/reethi-beach-resort",
		element: <Whr_reethi />
	},
	{
		path: "product/coldstores/mushroom",
		element: <Mushroom />
	},
	{
		path: "product/coldstores/potato",
		element: <Potato />
	},
	{
		path: "product/coldstores/floriculture",
		element: <Floriculture />
	},
	{
		path: 'product/mushroomfarms/samalkha',
		element: <Samalkha />
	},
	{
		path: 'product/mushroomfarms/murthal',
		element: <Murthal />
	},
	{
		path: 'product/mushroomfarms/garhmukhteshwar',
		element: <Garhmukhteshwar />
	},
	{
		path: 'product/slaughterhouse/nepal',
		element: <Nepal />
	},
	// {
	// 	path: "*",
	// 	element: <NotFound />
	// }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<RouterProvider router={router}>
		
	</RouterProvider>
);