import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import reethi_beach from '../../machines-images/Waste Heat Recovery/reethi_beach.jpeg'
import './wasteHeatRecovery.css'
import { Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function Wasteheatrecovery() {
  const navigate = useNavigate()
  return (
    <div>
        <Navbar />
        <div className='product-content'>
          <Typography className='product-title' textAlign='center' color='#36454F' variant='h3'>Genset Waste Heat Recovery to Utilization</Typography>
          <div className='divider'></div>
          <div className='product-images'>
            <div className='case-study'>
              <Typography textAlign='center' className='whr-locations' color='#71797E' variant='h4'>Reethi Beach Resort, Maldives</Typography>
              <img className='wasteHeatRecovery-cover-images' src={reethi_beach} alt='Waste heat recovery at Reethi Beach Resort'/>
              <Button onClick={() => navigate('/product/wasteheatrecovery/reethi-beach-resort')} className='whr-btn' variant='outlined'>Explore</Button>
            </div>
            <div className='case-study'>
              <Typography textAlign='center' className='whr-locations' color='#71797E' variant='h4'>Wynberg Allen School, Mussoorie</Typography>
              <img className='wasteHeatRecovery-cover-images' src={process.env.PUBLIC_URL + '/machines-images/Heat Pump/Property/wynberg.png'} alt='Waste heat recovery at Wenberg School' />
              <Button onClick={() => navigate('/product/wasteheatrecovery/wynberg')} className='whr-btn' variant='outlined'>Explore</Button>
            </div>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default Wasteheatrecovery