import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Typography, Grid, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './mushroomfarms.css'

import mushroom from '../../machines-images/Cold Stores/mushroom.jpeg'

function Mushroomfarms() {
  const navigate = useNavigate()

  const mushroom_sites = [
    {
        location: 'Samalkha, Haryana',
        production: '8 Rooms x 25 tons',
        redirect: 'samalkha',
        imagePath: '/machines-images/Mushroom Farms/samalkha.jpg'
    },
    {
        location: 'Garh Mukhteshwar, UP',
        production: '12 Rooms x 50 tons',
        redirect: 'garhmukhteshwar',
        imagePath: '/machines-images/Mushroom Farms/garhmukhteshwar.jpg'
    },
    {
        location: 'Murthal, Haryana',
        production: '2 Rooms x 20 tons',
        redirect: 'murthal',
        imagePath: '/machines-images/Mushroom Farms/murthal.jpg'
    }
  ]

  return (
    <div>
        <Navbar />
        <div className='mushroom-product-content'>
          <Typography className='mushroom-product-title' textAlign='center' fontWeight='bold' variant='h2' color='#36454F' mb={2}>Climate Control for Mushroom Farms</Typography>
          <Grid container justifyContent='center' className='mushroom-cards-container'>
          {mushroom_sites.map((site, index) => {
            return (
                <Grid md={6} item className='mushroom-card'>
                    <div>
                      <Typography className='mushroom-card-title' textAlign='center' fontWeight='bold' variant='h4' mb={2}>{site.location}</Typography>

                      <img src={process.env.PUBLIC_URL + site.imagePath} alt='mushroom farming' className='mushroom-card-image' />

                      <Typography textAlign='center' mb={2} variant='h5' color='#BEBEBE' className='mushroom-card-caption'>{site.production}</Typography>

                    </div>
                    <Button onClick={() => navigate('/product/mushroomfarms/' + site.redirect)} variant='contained' className='mushroom-card-button'>View More</Button>
              </Grid>
            )
          })}
          </Grid>
        </div>
        <Footer />
    </div>
  )
}

export default Mushroomfarms