import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import './heatpumpImages.css'
import ResponsiveGallery from 'react-responsive-gallery'

const images = [
    {
      src: "https://drive.google.com/uc?id=1xrvh1mzBTjqpw1Dzod-UGJtLO0MP0o-2"
    },
    {
      src: "https://drive.google.com/uc?id=101IVj69rJgDp7WlbjpRN-8aXTq2gcg47"
    },
    {
      src: "https://drive.google.com/uc?id=1khDmvBMRwIHmcV2AzHkaV6eCAObv5ac-"
    },
    {
      src: "https://drive.google.com/uc?id=1S5w2XAkX8TUyWdJVx6Mdbvu-BwQn4b09"
    },
    {
      src: "https://drive.google.com/uc?id=1Co_ILajA_wGxOHK7jdvTa1OT__hqjEIr"
    },
    {
      src: "https://drive.google.com/uc?id=1rWG495do8OHcWihrFH1zvBzakxHXpMh6"
    },
    {
      src: "https://drive.google.com/uc?id=1LHqwpf5fql4e56FMki4g1COGNwtj7o2R"
    },
    {
      src: "https://drive.google.com/uc?id=1XWFBg9G2IyK25GGerlXh6fabCbQENTmc"
    },
    {
      src: "https://drive.google.com/uc?id=1Cc3ubWj0oQwhxWP9V5rhmGp3IPqyYxUZ"
    },
    {
      src: "https://drive.google.com/uc?id=1koof916dceRyly6EKYVEosUShZqpPBZ6"
    },
    {
      src: "https://drive.google.com/uc?id=1T9wylKqWq1s2wvWMnOj7Ai-bTlux4TJF"
    },
    {
      src: "https://drive.google.com/uc?id=1agLOIJX_6dApUzX8Nw_FvjHc3FUrjlXh"
    },
    {
      src: "https://drive.google.com/uc?id=1R58HNgM5TyhX2b9sI8rFg72hRBntlDT5"
    },
    {
      src: "https://drive.google.com/uc?id=1OZ6be2G-g7Y8FjE6fjcICukTiSM-34Fa"
    },
    {
      src: "https://drive.google.com/uc?id=15VLuU21e_STAHE-2T8VJDFQ3IxJqYG7u"
    },
    {
      src: "https://drive.google.com/uc?id=1O5Qjp8qIrMSAAWZyVjAOKS3_CIUeey5t"
    },
    {
      src: "https://drive.google.com/uc?id=1rNJtSnEe_SAmkoYoeTvzRoPLEfIK84VB"
    },
    {
      src: "https://drive.google.com/uc?id=1Yki5fI-t4Hkxso55xbDghUvmqaX5N1U8"
    },
    {
      src: "https://drive.google.com/uc?id=1gK9FZ7X9JTdCNuyisZcVnYAtVyWsLeXw"
    },
    {
      src: "https://drive.google.com/uc?id=1-FTz5eIfrbyQmULjDdk17s-ax2BAJ-6F"
    },
    {
      src: "https://drive.google.com/uc?id=1kNAwmo0FuBLNPDB8P2qikqTy5YABgz_q"
    },
    {
      src: "https://drive.google.com/uc?id=1OpnbtVUF6Mm87GRNxZBf714xAtr7EF5q"
    },
    {
      src: "https://drive.google.com/uc?id=1iHjMOAKylfh70DxvkvZPltoqBmBw5v4t"
    },
    {
      src: "https://drive.google.com/uc?id=1uLtnD_rsNu-JRn3_dwphmJax6xDV3dt9"
    },
    {
      src: "https://drive.google.com/uc?id=1Lhpu5blKzz26D7v7QsekEtUnY7u-QNIj"
    },
    {
      src: "https://drive.google.com/uc?id=1kYUuNW8Kn1ADIibigCNqRVP0FaywmRwy"
    },
    {
      src: "https://drive.google.com/uc?id=1TT5bUPPZf8pcn7ITffbAD9aEiuCToToo"
    },
    {
      src: "https://drive.google.com/uc?id=1MJMvTQVnmD5Sb4VkWEhsbvUdHGNNSnJR"
    },
    {
      src: "https://drive.google.com/uc?id=1RtQGxZCOlxrWLObqieYd6WGIV8gJxvK5"
    },
    {
      src: "https://drive.google.com/uc?id=1qTSvqaloS7wYYTgmxM9XzCSzrWtmNHnU"
    },
    {
      src: "https://drive.google.com/uc?id=1acjBhDTf0q5ynkjjomIks2mzEjn4xYSs"
    },
    {
      src: "https://drive.google.com/uc?id=10eDLXVJwAoX4-7a69nIjjXL1uGJ-ej_s"
    },
    {
      src: "https://drive.google.com/uc?id=1zm9hSU7chqQaoQCxPPRgsX0CEFozLBdX"
    },
    {
      src: "https://drive.google.com/uc?id=1Ryd0SH2yiBQ1QUWvw7Vy2vnRauFQBpHA"
    },
    {
      src: "https://drive.google.com/uc?id=1JZNLiLLWrIPC8CMFCCA42aHZhOFu17R9"
    },
    {
      src: "https://drive.google.com/uc?id=1pUqaGTcrXh6td_oEkYNHbVF_U-yzj9op"
    },
    {
      src: "https://drive.google.com/uc?id=1050mZ-54iji8gRki3zdO4Iw5YM1Vw83D"
    },
    {
      src: "https://drive.google.com/uc?id=1D9jXBEgFN2JIYhAV0BqsyULUX9zysPpL"
    },
    {
      src: "https://drive.google.com/uc?id=1dd2JAIJdWEh8F78OlZcBbdsykpKEqrYj"
    },
    {
      src: "https://drive.google.com/uc?id=1nfOU6lWBuevfOMHYUkiKKMspfT6RPuty"
    },
    {
      src: "https://drive.google.com/uc?id=1zdnX2BkDe7i9YU4lbafN7qMIIQ5hN5wa"
    },
    {
      src: "https://drive.google.com/uc?id=17nEu8FdBHh-_VHorWVGAoWg90y-l6wep"
    },
    {
      src: "https://drive.google.com/uc?id=1ctVzm8UD49acvViKTTJT8HesMn-azkUE"
    },
    {
      src: "https://drive.google.com/uc?id=1MUNZYoravf80DREsDtd7s7ugHQc1to_q"
    },
    {
      src: "https://drive.google.com/uc?id=1SNy5ijG2UYiJi6oVSPf_kF2R8uymD5nb"
    },
    {
      src: "https://drive.google.com/uc?id=1MQOERTHR_QGlQI6WLdjywQTq13UvkOQ2"
    },
    {
      src: "https://drive.google.com/uc?id=1inMGleyJEY3I8UilJvx9oP9SC1g9ZmQT"
    },
    {
      src: "https://drive.google.com/uc?id=1JF5i6jptG-Y71dqKDax9-wz7vYioXOMW"
    },
    {
      src: "https://drive.google.com/uc?id=1h3n_KXRrbZ60ytLTZ-vccKt0LKCxVmpt"
    },
    {
      src: "https://drive.google.com/uc?id=1VtsBBJq6pSc_ShY-nnCDACAvb83tHPLz"
    },
    {
      src: "https://drive.google.com/uc?id=1BgUA1u7EW7wYq6sfeTJ3BANXwsT89IWe"
    },
    {
      src: "https://drive.google.com/uc?id=1b7mKhK3vxXSWd975_06xQ6KOsODBfuA5"
    },
    {
      src: "https://drive.google.com/uc?id=17YnRfMszKKcNuS5Po6smcKUcRuaHqVjU"
    },
    {
      src: "https://drive.google.com/uc?id=1bBJtwS05F3X-vITZHAviTF_sGGzow2dV"
    },
    {
      src: "https://drive.google.com/uc?id=1rBtC5m2wjtQpSPVQpP9ppdkvjwtyFWVH"
    },
    {
      src: "https://drive.google.com/uc?id=1VI1C_eUlqX6L0tMzO3KmgazCWtPFuL3n"
    },
    {
      src: "https://drive.google.com/uc?id=175Otnf9CeXWy8IicrI2-w7vzPrQPVuSV"
    },
]

function HeatpumpImages() {
  return (
    <div>
      <Navbar />
      <Footer />
      <div className='heatpumpImages-content'>
        <ResponsiveGallery images={images}/>
      </div>
    </div>
  )
}

export default HeatpumpImages