import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import iceMachineData from './product-data/iceMachineData'
import { Typography } from '@mui/material'
import chart_simple_solid from '../../icons/chart_simple_solid.svg'
import hand_pointer_solid from '../../icons/hand_pointer_solid.svg'
import mobile_screen_button_solid from '../../icons/mobile_screen_button_solid.svg'
import water_solid from '../../icons/water_solid.svg'
import './icemachine.css'

function Icemachine() {

  return (
    <div>
        <Navbar />
        <div className='product-content'>
          <div className='product-title-and-description'>
            <Typography className='product-title' fontWeight='bold' variant='h2' color='#36454F' mb={2}>Tube Ice Machines</Typography>
            <div className='icon-and-text'>
              <img src={hand_pointer_solid} style={{width: '20px'}} />
              <Typography className='description-text' variant='h5' color='#71797E'>Fully automatic - one touch operation</Typography>
            </div>
            <div className='icon-and-text'>
              <img src={chart_simple_solid} style={{width: '20px'}} />
              <Typography className='description-text' variant='h5' color='#71797E'>Graphic display of process and parameters for easy understanding</Typography>
            </div>
            <div className='icon-and-text'>
              <img src={water_solid} style={{width: '20px'}} />
              <Typography className='description-text' variant='h5' color='#71797E'>Ice water remains in contact with SS304, cPVC</Typography>
            </div>
            <div className='icon-and-text'>
              <img src={mobile_screen_button_solid} style={{width: '20px'}} />
              <Typography className='description-text' variant='h5' color='#71797E'>Control from smartphone or PC</Typography>
            </div>
          </div>

          {iceMachineData.map((iceMachine, index) => {
            return (
              <div key={index} className='ice-machine-wrapper'>
                <Typography variant='h4' className='ice-machine-title' fontWeight='bold' color='#86868b'>{iceMachine.name}</Typography>
                <div className='ice-machine-container'>
                  <div className='two-image-container'>
                    <img className='ice-machine-image' src={process.env.PUBLIC_URL + iceMachine.imagePath1} />
                    <img className='ice-machine-image' src={process.env.PUBLIC_URL + iceMachine.imagePath2} />
                  </div>
                  <div className='ice-machine-content'>
                    <div className='ice-machine-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Location: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{iceMachine.location}</Typography>
                    </div>
                    <div className='ice-machine-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Capacity: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{iceMachine.capacity}</Typography>
                    </div>
                    <div className='ice-machine-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Compressor: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{iceMachine.compressor}</Typography>
                    </div>
                    <div className='ice-machine-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Refrigerant: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{iceMachine.refrigerant}</Typography>
                    </div>
                    {iceMachine.water_cooled_condenser && <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>Water-Cooled condenser</Typography>}
                    <div className='ice-machine-specifications'>
                      <Typography variant='h5' mb={2} color='#86868b' className='bold-400 machine-text'>Controller: </Typography>
                      <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>{iceMachine.controller}</Typography>
                    </div>
                    {iceMachine.remote && <Typography variant='h5' mb={2} color='#36454F' className='machine-text'>Control using smartphone or PC</Typography>}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <Footer />
    </div>
  )
}

export default Icemachine