import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Typography, Grid, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './coldstore.css'

import floriculture from '../../machines-images/Cold Stores/floriculture.jpeg'
import potato_bulk_storage from '../../machines-images/Cold Stores/potato_bulk_storage.jpeg'
import mushroom from '../../machines-images/Cold Stores/mushroom.jpeg'
import mango from '../../machines-images/Cold Stores/mango.jpeg'

function Coldstore() {
  const navigate = useNavigate()

  return (
    <div>
        <Navbar />
        <div className='coldstore-product-content'>
          <Typography className='coldstore-product-title' textAlign='center' fontWeight='bold' variant='h2' color='#36454F' mb={2}>Refrigeration for Potato Storage</Typography>

          <Grid container justifyContent='center' className='coldstore-cards-container'>
            <Grid md={6} item className='coldstore-card'>
              <div className='coldstore-card-separate'>
                <div>
                  <Typography className='coldstore-card-title' textAlign='center' fontWeight='bold' variant='h4' mb={2}>Potato Bulk Storage</Typography>

                  <img src={potato_bulk_storage} alt='potato_bulk_storage' className='coldstore-card-image' />

                  <Typography className='coldstore-card-location'>Gujarat</Typography>

                  <div className='coldstore-card-points'>
                    <Typography className='coldstore-card-subtitle' style={{textAlign: 'center', marginBottom: '20px'}}>1500 MT x 2</Typography>
                    <Typography className='coldstore-card-point'>With Refrigeration DX Units (most Economical)</Typography>
                    <Typography className='coldstore-card-point'>Refrigeration for Temperature Control</Typography>
                    <Typography className='coldstore-card-point'>Humidifier for RH Control</Typography>
                    <Typography className='coldstore-card-point'>Ventilation through Waste-Heat Recovery for CO2 control</Typography>
                    <Typography className='coldstore-card-point'>PLC Automation for Simultaneous Control of Temperature, RH, CO2</Typography>
                  </div>

                </div>
                <Button onClick={() => navigate('/product/coldstores/potato')} variant='contained' className='coldstore-card-button'>View More</Button>

              </div>
            </Grid>
            
            {/* <Grid md={6} item className='coldstore-card'>
              <Typography className='coldstore-card-title' textAlign='center' fontWeight='bold' variant='h4' mb={2}>Mushroom Farming</Typography>

              <img src={mushroom} alt='mushroom farming' className='coldstore-card-image' />

              <Typography className='coldstore-card-location'>Haryana</Typography>

              <div className='coldstore-card-points'>
                <Typography className='coldstore-card-subtitle' style={{textAlign: 'center', marginBottom: '20px'}}>1 ton/day, 8 rooms</Typography>
                <Typography className='coldstore-card-point'>Refrigeration for Temperature Control</Typography>
                <Typography className='coldstore-card-point'>Humidifier for RH Control</Typography>
                <Typography className='coldstore-card-point'>Ventilation through Waste-Heat Recovery for CO</Typography>
                <Typography className='coldstore-card-point'>PLC Automation for Simultaneous Control of Temperature, RH, CO2</Typography>
              </div>

              <Button onClick={() => navigate('/product/coldstores/mushroom')} variant='contained' className='coldstore-card-button'>View More</Button>
            </Grid> */}

            {/* <Grid md={6} item className='coldstore-card'>
              <Typography className='coldstore-card-title' textAlign='center' fontWeight='bold' variant='h4' mb={2}>Floriculture</Typography>

              <img src={floriculture} alt='floriculture' className='coldstore-card-image' />

              <Typography className='coldstore-card-location'>Uttarakhand</Typography>

              <div className='coldstore-card-projects'>
                <div className='coldstore-card-points'>
                  <Typography className='coldstore-card-subtitle'>Project 1:</Typography>
                  <Typography className='coldstore-card-point'>Pre-Cooler for +4 °C</Typography>
                  <Typography className='coldstore-card-point'>Storage - Temperature Control</Typography>
                </div>

                <div className='coldstore-card-points'>
                  <Typography className='coldstore-card-subtitle'>Project 2:</Typography>
                  <Typography className='coldstore-card-point'>Storage - Temperature control</Typography>
                </div>
              </div>

              <Button onClick={() => navigate('/product/coldstores/floriculture')} variant='contained' className='coldstore-card-button'>View More</Button>
            </Grid>

            <Grid md={6} item className='coldstore-card'>
              <Typography className='coldstore-card-title' textAlign='center' fontWeight='bold' variant='h4' mb={2}>Mango Packhouse</Typography>

              <img src={mango} alt='mango packhouse' className='coldstore-card-image' />

              <Typography className='coldstore-card-location'>Uttar Pradesh</Typography>
              <div className='coldstore-card-points'>
                <Typography className='coldstore-card-subtitle'>Equipments:</Typography>
                <Typography className='coldstore-card-point'>High RH Pre-Cooler for +4 °C</Typography>
                <Typography className='coldstore-card-point'>Storage - Temperature Control</Typography>
              </div>

              <Button onClick={() => navigate('/product/coldstores/mango')} variant='contained' className='coldstore-card-button'>View More</Button>
            </Grid> */}

          </Grid>
        </div>
        <Footer />
    </div>
  )
}

export default Coldstore