import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Typography } from '@mui/material'
import './contactus.css'
// import contact_us.svg from '../undraw/contact_us.svg'
import contact_us from '../undraw/contact_us.svg'
import phone_gif from '../animated-icons/phone_gif.gif'
import mail_gif from '../animated-icons/mail_gif.gif'
import location_gif from '../animated-icons/location_gif.gif'

function Contactus() {
  return (
    <div>
        <Navbar />
        <Footer />
        <div className="contactus-content">
          <Typography id="contactus-title" variant="h2" fontWeight={500}>Contact Us</Typography>
          <img src={contact_us} id='contactus-contact_us' alt="contact_us" />
          <div className="contactus-main">
            <div className="contactus-call">
              <img src={phone_gif} style={{width: '25px'}}></img>
              <div className="contactus-numbers">
                <div className="contactus-names">
                  <Typography className='contactus-text'>Animesh :</Typography>
                  <Typography className='contactus-text'>Honey :</Typography>
                  <Typography className='contactus-text'>Arun :</Typography>
                </div>
                <div className="contactus-name-number">
                  <Typography className='contactus-text blue underline'>+91 98102 80278</Typography>
                  <Typography className='contactus-text blue underline'>+91 97160 64701</Typography>
                  <Typography className='contactus-text blue underline'>+91 96500 12315</Typography>
                </div>
              </div>
            </div>
            <div className="contactus-mail">
              <img src={mail_gif} style={{width: '25px'}}></img>
              <Typography className='contactus-text'> info@celsius.in </Typography>
            </div>
            <div className="contactus-address">
              <img src={location_gif} style={{width: '25px'}}></img>
              <Typography className='contactus-text'> E199, EPIP, RIICO Industrial Area, Neemrana, Rajasthan 301705 </Typography>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Contactus
