import React from 'react'
import './footer.css'
import { Typography } from '@mui/material'

function Footer() {
  return (
    <div>
        <div className="footer">
            <div className="footer-copyright">
                <Typography className='footer-text'>© Celsius, 2024</Typography>
            </div>
            <div className="footer-mail">
                <i className="fa-solid fa-envelope"></i>
                <a href='mailto:info@celsius.in'>info@celsius.in</a>
            </div>
        </div>
    </div>
  )
}

export default Footer