import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Grid, Typography, Button } from '@mui/material'
import processChillerData from './product-data/processChillerData'
import './processChiller.css'

function Processchiller() {

  return (
    <div>
        <Navbar />
        <div className='product-content'>
          <div className='product-title-and-description'>
            <Typography className='product-title' fontWeight='bold' variant='h2' color='#36454F' mb={2}>Process Chillers</Typography>
          </div>

          <Grid justifyContent='center' container className='product-grid'>
            {processChillerData.map((processChiller, index) => {
              return (
                <Grid key={index} className='product-card' s={12} md={6} l={6} item>
                  <div className='card-header'>
                    <Typography fontWeight='bold' className='card-header-text' variant='h4' color='#86868b'>{processChiller.application}</Typography>
                  </div>
                  <img className='processChiller-image' src={process.env.PUBLIC_URL + processChiller.imagePath} />
                  <div className='card-text-container'>
                    <div className='card-text'>
                      <Typography variant='h6' color='#71797E' textAlign='center'>{processChiller.name}</Typography>
                    </div>
                    <div className='card-specifications'>
                      <Typography fontSize='18px' fontWeight='bold' mb={1}>Installation: </Typography>
                      <Typography fontSize='18px'>{processChiller.installation}</Typography>
                    </div>
                    <div className='card-specifications'>
                      <Typography fontSize='18px' fontWeight='bold' mb={1}>Cooling Capacity: </Typography>
                      <Typography fontSize='18px'>{processChiller.cooling_capacity}</Typography>
                    </div>
                    {processChiller.compressor && <div className='card-specifications'>
                      <Typography fontSize='18px' fontWeight='bold' mb={1}>Compressor: </Typography>
                      <Typography fontSize='18px' mb={1}>{processChiller.compressor}</Typography>
                    </div>}
                    {processChiller.plc && <div className='card-specifications'>
                      <Typography fontSize='18px' mb={1}>{processChiller.plc}</Typography>
                    </div>}
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </div>
        <Footer />
    </div>
  )
}

export default Processchiller