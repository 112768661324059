import React from 'react'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { Typography, Button, Grid } from '@mui/material'
import {useNavigate} from 'react-router-dom'
import './App.css'

function App() {
  const navigate = useNavigate()

  const [width, setWidth] = React.useState(window.innerWidth);

  // dynamically updated viewport width (for responsive design)
  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const machines = [
    {
      name: 'CO₂ and N₂O Liquefaction',
      redirect: 'co2liquefaction',
      caption: 'For CO₂ Recovery & Generation Plants',
      imagePath: '/machines-images/CO2 Liquefaction/kanpur_3600kg.jpg'
    },
    {
      name: 'CO₂ and N₂O Storage',
      redirect: 'co2tankstorage',
      caption: 'Maintain CO₂ Pressure in Tanks',
      imagePath: '/machines-images/CO2 Tank/Abu_70klx3.jpg'
    },
    {
      name: 'Heat Pumps',
      redirect: 'heatpumps',
      caption: 'For Pools and Bathrooms',
      imagePath: '/machines-images/Heat Pump/Machine/Dehradun_Heat_Pump.jpeg'
    },
    {
      name: 'Climate Control for Mushroom Farms',
      redirect: 'mushroomfarms',
      caption: '',
      imagePath: '/machines-images/Mushroom Farms/mushroom.jpeg'
    },
    {
      name: 'Refrigeration for Slaughterhouse',
      redirect: 'slaughterhouse',
      caption: '',
      imagePath: '/machines-images/Slaughterhouse/slaughterhouse.jpeg'
    },
    {
      name: 'Ice Machines',
      redirect: 'icemachines',
      caption: 'Tube & Flake',
      imagePath: '/machines-images/Tube Ice Machine/cbpl_52.jpg'
    },
    {
      name: 'Process Chillers',
      redirect: 'processchillers',
      caption: 'Cooling Oil, Milk, Exo-Gas',
      imagePath: '/machines-images/Process Chiller/milkchiller80hp.jpg'
    },
    {
      name: 'Refrigeration for Potato Storage',
      redirect: 'coldstores',
      caption: 'For Fruits, Vegetables and Flowers',
      imagePath: '/machines-images/Cold Stores/mushroom1.jpeg'
    },
    // {
    //   name: 'Dehumidifiers',
    //   redirect: 'dehumidifiers',
    //   caption: 'Air drying to maintain Low RH',
    //   imagePath: [heat_pump]
    // },
    {
      name: 'Waste Heat Recovery',
      redirect: 'wasteheatrecovery',
      caption: 'Hot Water for Pools & Bathrooms from WHR from Power Generators or Chillers',
      imagePath: '/machines-images/Waste Heat Recovery/reethi_beach.jpeg'
    }
  ]

  return (
    <>
      <Navbar />
      <Footer />
      <div className='app-content'>
        {machines.map((machine, index) => {
          if(width < 1000) {
            return (
              <div key={index} className={`app-machine-info-mobile ${index % 2 === 0 ? 'app-black': 'app-white'}`}>
                <div className='app-title-and-caption-mobile'>
                  <Typography textAlign='center' variant='h4' className='app-title-mobile'>{machine.name}</Typography>
                  <Typography textAlign='center' className='app-caption-mobile' color='#86868b'>{machine.caption}</Typography>
                </div>
                <img src={process.env.PUBLIC_URL + machine.imagePath} id='app-machine-pic-mobile' />
                <Button onClick={() => navigate(`/product/${machine.redirect}`)} className='app-explore-btn-mobile' style={{ padding: '5px 10px', textTransform: 'unset', color: `${index % 2 == 0 ? 'white' : 'black'}`, borderColor: `${index % 2 == 0 ? 'white' : 'black'}`, fontSize: '18px' }} variant='outlined'>Explore {machine.name}</Button>
              </div>
            )
          } else {
            return (
              <Grid key={index} direction={index % 2 == 0 ? 'row' : 'row-reverse'} className={`${index % 2 === 0 ? 'app-black': 'app-white'}`} justifyContent='center' alignItems='center' container>
                <Grid md={6} xl={6} s={12} className='app-machine-info' item>
                  <div className='app-title-and-caption'>
                    <Typography textAlign='center' className='app-title'>{machine.name}</Typography>
                    <Typography textAlign='center' className='app-caption' color='#86868b'>{machine.caption}</Typography>
                  </div>
                  <Button onClick={() => navigate(`/product/${machine.redirect}`)} style={{ padding: '5px 10px', textTransform: 'unset', color: `${index % 2 == 0 ? 'white' : 'black'}`, borderColor: `${index % 2 == 0 ? 'white' : 'black'}`, fontSize: '20px' }} variant='outlined'>Explore {machine.name}</Button>
                </Grid>
                <Grid md={6} className='app-image' item>
                  <img src={process.env.PUBLIC_URL + machine.imagePath} id='app-machine-pic' />
                </Grid>
              </Grid>
            )
          }
        })}
      </div>
    </>
  )
}
 
export default App
