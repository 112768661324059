import React from 'react'
import Navbar from '../../../components/Navbar'
import Footer from '../../../components/Footer'
import './floriculture.css'
import floricultureData from './floricultureData'
import { Typography, Grid } from '@mui/material'

import cold1 from '../../../machines-images/Cold Stores/Floriculture/cold1.jpg'
import cold from '../../../machines-images/Cold Stores/Floriculture/cold.jpg'
import highrh from '../../../machines-images/Cold Stores/Floriculture/highrh.jpg'
import sidcule from '../../../machines-images/Cold Stores/Floriculture/sidcule.jpg'
import Floriculture12 from '../../../machines-images/Cold Stores/Floriculture/Floriculture12.jpg'
import Floriculture2 from '../../../machines-images/Cold Stores/Floriculture/Floriculture2.jpg'
import maldives from '../../../machines-images/Cold Stores/Floriculture/maldives.jpg'
import reethi from '../../../machines-images/Cold Stores/Floriculture/reethi.jpg'
import Seasons2 from '../../../machines-images/Cold Stores/Floriculture/Seasons2.jpg'
import seasons from '../../../machines-images/Cold Stores/Floriculture/seasons.jpg'
import akshardham1 from '../../../machines-images/Cold Stores/Floriculture/akshardham1.jpg'
import akshardham2 from '../../../machines-images/Cold Stores/Floriculture/akshardham2.jpg'
import countryinn from '../../../machines-images/Cold Stores/Floriculture/countryinn.jpg'
import sibang from '../../../machines-images/Cold Stores/Floriculture/sibang.jpg'
import sibang1 from '../../../machines-images/Cold Stores/Floriculture/sibang1.jpg'
import khera from '../../../machines-images/Cold Stores/Floriculture/khera.jpg'
import khera1 from '../../../machines-images/Cold Stores/Floriculture/khera1.jpg'
import landmark from '../../../machines-images/Cold Stores/Floriculture/landmark.jpg'
import landmark1 from '../../../machines-images/Cold Stores/Floriculture/landmark1.jpg'

function Floriculture() {
    const images = [[cold1, cold], [sidcule, highrh], [Floriculture12, Floriculture2], [maldives, reethi], [Seasons2, seasons], [akshardham1, akshardham2], [countryinn, countryinn], [sibang, sibang1], [khera, khera1], [landmark, landmark1]]

    return (
        <div>
            <Navbar />
            <div className='product-content'>
                <Typography className='flori-title' fontWeight='bold' variant='h2' color='#36454F' mb={2}>Floriculture</Typography>
                <div className='flori-card-container'>
                    {floricultureData.map((data, index) => (
                        <div key={index} className='flori-card'>
                            <img className='flori-image' src={images[index][0]} />
                            <div className='flori-card-content'>
                                <Typography className='flori-location' textAlign='center' fontWeight='bold' color='#86868b'>{data.location}</Typography>
                                <div>
                                    {data.rooms && <div className='flori-info'>
                                        <Typography className='flori-subtitle' color='#71797E'>Rooms: </Typography>
                                        <Typography className='flori-text'>{data.rooms}</Typography>
                                    </div>}
                                    {data.room1 && <div className='flori-info'>
                                        <Typography className='flori-subtitle' color='#71797E'>Room 1: </Typography>
                                        <Typography className='flori-text'>{data.room1}</Typography>
                                    </div>}
                                    {data.room2 && <div className='flori-info'>
                                        <Typography className='flori-subtitle' color='#71797E'>Room 2: </Typography>
                                        <Typography className='flori-text'>{data.room2}</Typography>
                                    </div>}
                                    {data.room && <div className='flori-info'>
                                        <Typography className='flori-subtitle' color='#71797E'>Room: </Typography>
                                        <Typography className='flori-text'>{data.room}</Typography>
                                    </div>}
                                </div>
                            </div>
                            <img className='flori-image' src={images[index][1]} />
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Floriculture