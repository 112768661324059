import React from 'react'
import Navbar from '../../../components/Navbar'
import Footer from '../../../components/Footer'
import './mushroom.css'

import Slide1 from '../../../machines-images/Cold Stores/mushrooms/Slide1.JPG'
import Slide3 from '../../../machines-images/Cold Stores/mushrooms/Slide3.JPG'
import Slide4 from '../../../machines-images/Cold Stores/mushrooms/Slide4.JPG'
import Slide5 from '../../../machines-images/Cold Stores/mushrooms/Slide5.JPG'
import Slide6 from '../../../machines-images/Cold Stores/mushrooms/Slide6.JPG'
import Slide7 from '../../../machines-images/Cold Stores/mushrooms/Slide7.JPG'
import Slide8 from '../../../machines-images/Cold Stores/mushrooms/Slide8.JPG'
import Slide9 from '../../../machines-images/Cold Stores/mushrooms/Slide9.JPG'
import Slide10 from '../../../machines-images/Cold Stores/mushrooms/Slide10.JPG'
import Slide11 from '../../../machines-images/Cold Stores/mushrooms/Slide11.JPG'
import Slide12 from '../../../machines-images/Cold Stores/mushrooms/Slide12.JPG'
import Slide13 from '../../../machines-images/Cold Stores/mushrooms/Slide13.JPG'
import Slide14 from '../../../machines-images/Cold Stores/mushrooms/Slide14.JPG'
import Slide15 from '../../../machines-images/Cold Stores/mushrooms/Slide15.JPG'
import Slide16 from '../../../machines-images/Cold Stores/mushrooms/Slide16.JPG'
import Slide17 from '../../../machines-images/Cold Stores/mushrooms/Slide17.JPG'
import Slide18 from '../../../machines-images/Cold Stores/mushrooms/Slide18.JPG'
import Slide19 from '../../../machines-images/Cold Stores/mushrooms/Slide19.JPG'
import Slide20 from '../../../machines-images/Cold Stores/mushrooms/Slide20.JPG'
import Slide22 from '../../../machines-images/Cold Stores/mushrooms/Slide22.JPG'
import Slide23 from '../../../machines-images/Cold Stores/mushrooms/Slide23.JPG'
import Slide24 from '../../../machines-images/Cold Stores/mushrooms/Slide24.JPG'
import Slide25 from '../../../machines-images/Cold Stores/mushrooms/Slide25.JPG'
import Slide26 from '../../../machines-images/Cold Stores/mushrooms/Slide26.JPG'
import Slide27 from '../../../machines-images/Cold Stores/mushrooms/Slide27.JPG'
import Slide28 from '../../../machines-images/Cold Stores/mushrooms/Slide28.JPG'
import Slide29 from '../../../machines-images/Cold Stores/mushrooms/Slide29.JPG'
import Slide30 from '../../../machines-images/Cold Stores/mushrooms/Slide20.JPG'
import Slide31 from '../../../machines-images/Cold Stores/mushrooms/Slide31.JPG'
import Slide32 from '../../../machines-images/Cold Stores/mushrooms/Slide32.JPG'
import Slide33 from '../../../machines-images/Cold Stores/mushrooms/Slide33.JPG'

function Mushroom() {
    const images = [Slide1, Slide3, Slide4, Slide5, Slide6, Slide7, Slide8, Slide9, Slide10, Slide11, Slide12, Slide13, Slide14, Slide15, Slide16, Slide17, Slide18, Slide19, Slide20, Slide22, Slide23, Slide24, Slide25, Slide26, Slide27, Slide28, Slide29, Slide30, Slide31, Slide32, Slide33]
    return (
        <div>
            <Navbar />
            <div className='product-content'>
                {images.map((image, index) => (<img key={index} src={image} />))}
            </div>
            <Footer />
        </div>
    )
}

export default Mushroom