import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Grid, Typography, Button } from '@mui/material'
import CountUp from 'react-countup';
import './heatpump.css'
import { useNavigate } from 'react-router-dom';
import heatpumpData from './product-data/heatpumpData';
import heat_pump_catalogue from '../../Catalogues/heat_pump_catalogue.pdf'

function Heatpump() {
    const navigate = useNavigate()
        
    return (
      <div>
          <Navbar />
          <div className='product-content'>
            <div className='product-title-and-description'>
              <Typography className='product-title' fontWeight='bold' variant='h2' color='#36454F' mb={2}>Heat Pumps</Typography>
              <Typography variant='h5' mb={2} color='#71797E'>We make heating water systems for <span style={{color: '#36454F', fontWeight: 500}}>hostels</span>, <span style={{color: '#36454F', fontWeight: 500}}>hotels</span> and <span style={{color: '#36454F', fontWeight: 500}}>swimming pools</span></Typography>
              <Typography variant='h5' color={'#71797E'}>Running cost less than <span style={{color: '#36454F', fontWeight: 900}}><CountUp start={100} end={50} duration={2} />%</span> of boiler and <span style={{color: '#36454F', fontWeight: 900}}><CountUp start={100} end={25} duration={2} />%</span> of electric geyser</Typography>
            </div>

            <div className='heatpump-btns'>
              <a href={heat_pump_catalogue} download='celsius-heat-pump'>
                <Button className='heatpump-btn' variant='outlined'>View Catalogue</Button>
              </a>
              {/* <Button className='heatpump-btn' variant='outlined' onClick={() => navigate('/product/heatpump/imagegallery')}>Image Gallery</Button> */}
            </div>

            <Grid justifyContent='center' container className='product-grid'>
              {heatpumpData.map((heatPump, index) => {
                return (
                  <Grid key={index} className='product-card' s={12} md={6} l={6} item>
                    <div className='card-header'>
                      <Typography fontWeight='bold' className='card-header-text' variant='h4' color='#86868b'>{heatPump.site}</Typography>
                    </div>
                    <div className='heatpump-image-container'>
                      <img className='heatpump-image' src={process.env.PUBLIC_URL + heatPump.imagePath} />
                    </div>
                    <div className='card-text-container'>
                      <div className='card-text'>
                        <Typography variant='h6' color='#71797E' style={{margin: '0 auto'}}>{heatPump.application}</Typography>
                      </div>
                      {heatPump.installation &&  <div className='card-specifications'>
                        <Typography fontSize='18px' fontWeight='bold' mb={1}>Installation: </Typography>
                        <Typography fontSize='18px'>{heatPump.installation}</Typography>
                      </div>} 
                      <div className='card-specifications'>
                        <Typography fontSize='18px' fontWeight='bold' mb={1}>Heating Capacity: </Typography>
                        <Typography fontSize='18px'>{heatPump.heatingCapacity}</Typography>
                      </div>
                      <div className='card-specifications'>
                        <Typography fontSize='18px' fontWeight='bold' mb={1}>Power Input: </Typography>
                        <Typography fontSize='18px'>{heatPump.powerInput}</Typography>
                      </div>
                      <div>
                        <Typography fontSize='20px' fontWeight='bold' mb={1} textAlign='center' color='#71797E'>{heatPump.extraText}</Typography>
                      </div>
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          </div>
          <Footer />
      </div>
    )
}

export default Heatpump