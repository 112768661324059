import React from 'react'
import Navbar from '../../../components/Navbar'
import Footer from '../../../components/Footer'
import './potato.css'
import { Typography, Grid } from '@mui/material'

import astha11 from '../../../machines-images/Cold Stores/Potato Bulk Storage/astha11.jpg'
import astha22 from '../../../machines-images/Cold Stores/Potato Bulk Storage/astha22.jpg'
import astha33 from '../../../machines-images/Cold Stores/Potato Bulk Storage/astha33.jpg'
import astha44 from '../../../machines-images/Cold Stores/Potato Bulk Storage/astha44.jpg'
import astha1 from '../../../machines-images/Cold Stores/Potato Bulk Storage/astha1.jpeg'
import astha2 from '../../../machines-images/Cold Stores/Potato Bulk Storage/astha2.jpeg'
import astha3 from '../../../machines-images/Cold Stores/Potato Bulk Storage/astha3.jpeg'
import astha4 from '../../../machines-images/Cold Stores/Potato Bulk Storage/astha4.jpeg'

function Potato() {
    return (
        <div>
            <Navbar />
            <div className='product-content'>
                <Typography className='potato-title' fontWeight='bold' variant='h2' color='#36454F' mb={2}>Potato Bulk Storage</Typography>
                <div className='modelling-3d'>
                    <Typography className='potato-subtitle' fontWeight='bold' variant='h4' color='#708090' textAlign='center' ml={2} mb={2}>3D Modelling (Concept)</Typography>
                    <Grid container spacing={5} justifyContent='center' className='potato-grid1'>
                        <Grid l={3} md={3} s={12} xs={12} item className='potato-grid1-item'>
                            <img src={astha11} alt='astha11' className='potato-grid1-image' />
                            <Typography className='potato-grid1-image-description'>1500 MT Potato Bulk Storage Room with Pressure Wall</Typography>
                        </Grid>
                        <Grid l={3} md={3} s={12} xs={12} item className='potato-grid1-item'>
                            <img src={astha22} alt='astha22' className='potato-grid1-image' />
                            <Typography className='potato-grid1-image-description'>Indoor Units, Waste-Heat Recovery Unit & Circulation Fans</Typography>
                        </Grid>
                        <Grid l={3} md={3} s={12} xs={12} item className='potato-grid1-item'>
                            <img src={astha33} alt='astha33' className='potato-grid1-image' />
                            <Typography className='potato-grid1-image-description'>View Without Ceiling & Rear Wall</Typography>
                        </Grid>
                        <Grid l={3} md={3} s={12} xs={12} item className='potato-grid1-item'>
                            <img src={astha44} alt='astha44' className='potato-grid1-image' />
                            <Typography className='potato-grid1-image-description'>Outdoor Units, Cooling Tower (View from behind  Pressure Wall)</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className='potato-site-images'>
                    <Typography className='potato-subtitle' fontWeight='bold' variant='h4' color='#708090' textAlign='center' ml={2} mb={2}>Actual Site Pictures</Typography>
                    <Grid container spacing={5} justifyContent='center' className='potato-grid2'>
                        <Grid l={6} md={6} s={12} xs={12} item className='potato-grid2-item'>
                            <div className='potato-grid2-card'>
                                <img src={astha1} alt='astha1' className='potato-grid2-image' />
                                <Typography className='potato-grid2-image-description'>1500 MT Potato Bulk Storage Room with Pressure Wall</Typography>
                            </div>
                        </Grid>
                        <Grid l={6} md={6} s={12} xs={12} item className='potato-grid2-item'>
                            <div className='potato-grid2-card'>
                                <img src={astha2} alt='astha2' className='potato-grid2-image' />
                                <Typography className='potato-grid2-image-description'>Circulation fans behind pressure wall</Typography>
                            </div>
                        </Grid>
                        <Grid l={6} md={6} s={12} xs={12} item className='potato-grid2-item'>
                            <div className='potato-grid2-card'>
                                <img src={astha3} alt='astha3' className='potato-grid2-image' />
                                <Typography className='potato-grid2-image-description'>Outdoor units and cooling tower</Typography>
                            </div>
                        </Grid>
                        <Grid l={6} md={6} s={12} xs={12} item className='potato-grid2-item'>
                            <div className='potato-grid2-card'>                              
                                <img src={astha4} alt='astha4' className='potato-grid2-image' />
                                <Typography className='potato-grid2-image-description'>Electrical cum control panel: Fully automatic through PLC & Cloud Monitoring for Simultaneous Control of Temperature, RH & CO₂</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Potato