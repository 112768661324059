import React from 'react'
import Navbar from '../../../components/Navbar'
import Footer from '../../../components/Footer'
import b1 from '../../../machines-images/Waste Heat Recovery/b1.jpg'
import b2 from '../../../machines-images/Waste Heat Recovery/b2.jpg'
import b3 from '../../../machines-images/Waste Heat Recovery/b3.jpg'
import b4 from '../../../machines-images/Waste Heat Recovery/b4.jpg'
import b5 from '../../../machines-images/Waste Heat Recovery/b5.jpg'
import b6 from '../../../machines-images/Waste Heat Recovery/b6.jpg'
import b7 from '../../../machines-images/Waste Heat Recovery/b7.jpg'
import './whr.css'

function Whr_wynberg() {
    const images = [b1, b2, b3, b4, b5, b6, b7]
    return (
        <div>
            <Navbar />
            <div className='product-content'>
                {images.map((image, index) => (<img key={index} className='whr-slides' src={image} />))}
            </div>
            <Footer />
        </div>
    )
}

export default Whr_wynberg